import axios from 'axios'

axios.defaults.baseURL = 'https://ios.whistle3.com'
// axios.defaults.baseURL = 'https://api.xn--6orz56b.com'

const service = axios.create({
  timeout: 40000,
  headers: {
    'Content-Type': "application/json",
    'X-Language': localStorage.getItem("lang"),
    'X-Token': ''
  },
})
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
  }
)
export default service
