<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h1>Whistle 개인정보 보호 및 데이터 사용 정책</h1>
		<p>개인정보 보호 및 데이터 사용 정책은 Whistle 기업 사명의 일부이며 회원들이 Whistle과 Whistle 플랫폼 사용에 대해 보다 개방적이고 친밀하게 이해할 수 있도록 하는 기반입니다. 이 정책은 당사가 수집하는 정보, 사용 및 공유 방법을 설명합니다. 개인 정보 보호 기본 사항에서 추가 도구 및 정보에 대해 알아볼 수 있습니다. </p>
		<p>당사의 정책을 검토할 때 이러한 정책은 독립적인 개인정보 보호정책이 없거나 이 정책과 관련된 모든 Whistle 플랫폼 브랜드, 제품 및 서비스에 적용된다는 점을 기억하십시오. 당사는 이를 "Whistle 서비스" 또는 " 제공하다". </p>
		<h2>1조 당사는 어떤 정보를 수집하나요? </h2>
		<p>당사는 귀하가 사용하는 서비스에 따라 귀하로부터 또는 귀하에 관한 다양한 정보를 수집합니다. </p>
		<p>1. 귀하가 수행하는 작업 및 귀하가 제공하는 정보. </p>
		<p>
		당사는 귀하가 계정 등록, 메시지 작성 또는 공유, 다른 사람과의 메시지 작성 또는 통신 등 귀하가 당사 서비스를 이용할 때 제공하는 콘텐츠 및 기타 정보를 수집합니다. 여기에는 귀하가 제공한 콘텐츠 정보나 사진 위치, 파일 생성 날짜 등 귀하에 관한 정보가 포함됩니다. 또한 당사는 귀하가 보거나 참여하는 콘텐츠 유형, 그러한 활동의 ​​빈도 및 기간 등 귀하가 당사 서비스를 사용하는 방식에 대한 정보를 수집합니다.
		</p>
		<p>2. 타인이 수행한 작업 및 제공되는 정보. </p>
		<p>또한 당사는 다른 사람이 당사 서비스를 사용할 때 제공하는 콘텐츠와 정보도 수집합니다. 여기에는 귀하의 사진을 공유하거나, 귀하에게 메시지를 보내거나, 연락처를 업로드, 동기화 또는 가져오는 등 귀하에 관한 정보가 포함됩니다. 정보의 시간. </p>
		<p>3. 개인 네트워크 및 관계 네트워크. </p>
		<p>우리는 귀하가 회원으로 속해 있는 연락처 및 그룹에 대한 정보뿐만 아니라 귀하가 가장 많이 소통하는 개인이나 공유하고 싶은 그룹 등 귀하와의 상호 작용 수준에 대한 정보도 수집합니다. 또한 당사는 귀하가 귀하의 장치에서 업로드, 동기화 또는 가져오는 연락처 정보(예: 주소록)를 수집합니다. </p>
		<p>4. 결제 정보. </p>
		<p>
		귀하가 구매 또는 금융 거래(예: Whistle에서 쇼핑, 게임 내 구매 또는 기부)를 위해 당사 서비스를 이용하는 경우, 당사는 해당 구매 또는 거래에 대한 정보를 수집합니다. 여기에는 신용카드 또는 직불카드 번호, 기타 카드 정보, 기타 계정 및 인증 정보, 청구, 배송 및 연락처 세부정보와 같은 결제 정보가 포함됩니다.
		</p>
		<p>5. 장비 정보. </p>
		<p>
		또한 당사는 귀하가 부여한 권한에 따라 귀하가 당사 서비스를 설치하거나 액세스하는 컴퓨터, 휴대폰 또는 기타 장치로부터 또는 이에 대한 정보를 수집합니다. 당사는 귀하에게 다양한 장치에서 일관된 서비스를 제공할 수 있도록 귀하의 다양한 장치에서 수집된 정보를 연관시킬 수 있습니다. 다음은 당사가 수집하는 기기 정보의 몇 가지 예입니다.
		1) 운영 체제, 하드웨어 버전, 장치 설정, 파일 및 소프트웨어 이름과 유형, 배터리 및 신호 강도, 장치 식별자와 같은 속성.
		2) GPS, Bluetooth 또는 WiFi 신호를 통해 얻은 위치 정보와 같은 특정 지리적 위치를 포함한 장치 위치.
		3) 귀하의 이동통신사 또는 ISP 이름, 브라우저 유형, 언어 및 시간대, 휴대전화 번호 및 IP 주소와 같은 연결 정보.
		4) 당사 서비스를 사용하는 웹사이트 및 애플리케이션의 정보. 당사는 귀하가 당사 서비스를 사용하는 제3자 웹사이트 및 앱을 방문하거나 사용할 때(예: 좋아요 버튼 또는 휘파람 로그인을 제공하거나 측정 서비스 및 광고 서비스를 사용할 때) 정보를 수집합니다. 수집된 정보에는 귀하가 방문하는 웹사이트 및 앱에 대한 정보, 해당 웹사이트 및 앱에서 귀하가 사용하는 당사 서비스, 앱이나 웹사이트 개발자 또는 게시자가 귀하 또는 당사에 제공한 정보가 포함됩니다.
		5) 제3자 파트너가 제공한 정보. 당사는 서비스를 제공하기 위해 해당 파트너와 협력할 때 파트너로부터 받는 정보 또는 광고주로부터 귀하에 대해 받는 정보 등 제3자 파트너로부터 Whistle을 사용하거나 사용하지 않는 동안 귀하 및 귀하의 활동에 대한 정보를 받습니다. 당신이 상호 작용하는 정보.
		6) 휘슬의 회사. Whistle이 소유하거나 운영하는 회사의 경우, 당사는 해당 회사의 관련 약관 및 정책에 따라 해당 회사로부터 귀하에 관한 정보를 받게 됩니다. 해당 회사와 해당 회사의 개인정보 보호정책에 대해 자세히 알아보세요.
		</p>
		<p>6、얼굴 데이터에 대한 성명.</p>
		<p>저희는 사용자의 프라이버시와 데이터 안전을 존중합니다.앱은 사용자의 얼굴 데이터를 수집, 저장 또는 업로드하지 않습니다.응용 프로그램의 미안 기능은 장치의 로컬 카메라와 이미지 처리 기술만 사용하며, 모든 처리 과정은 장치에서 이루어지며, 어떠한 형태의 생체 정보 추출, 저장 또는 공유와도 관련되지 않는다.</p>
		<h2>제 2조 이 정보를 어떻게 사용합니까? </h2>
		<p>우리는 사용자를 위한 매력적이고 맞춤화된 환경을 만드는 데 열정을 쏟고 있습니다. 우리는 받은 모든 정보를 사용하여 서비스를 제공하고 지원합니다. 특정 작업:</p>
		<p>1) 서비스를 제공, 개선, 개발합니다. 이 정보를 사용하여 당사는 귀하가 당사 서비스를 사용하고 상호 작용하는 방식, Whistle 서비스를 사용하는 동안과 사용하지 않고 귀하가 연결되고 관심이 있는 사람 및 사물과 상호 작용하는 방식을 이해할 수 있으므로 귀하에게 서비스를 제공할 수 있습니다. , 추천. </p>
		<p>2) 당사는 또한 이 정보를 사용하여 귀하에게 바로가기와 제안을 제공합니다. 예를 들어, 귀하가 태그된 사진과 당사가 수집한 사진을 비교하여 귀하의 친구가 사진에 귀하를 태그하도록 제안할 수 있습니다. 이 기능이 켜져 있는 경우 타임라인 및 태그 설정을 사용하여 다른 사용자가 사진에 귀하를 태그하도록 제안할 수 있는지 여부를 제어할 수 있습니다. </p>
		<p>3) 위치 정보를 얻은 후 당사는 이 정보를 사용하여 귀하와 다른 사람들에게 맞는 서비스를 제공합니다. 예를 들어 귀하가 체크인하고 해당 지역의 지역 이벤트나 혜택을 찾는 데 도움을 주거나 친구에게 귀하가 근처에 있음을 알리는 데 도움을 줍니다. </p>
		<p>4) 당사는 제품 및 서비스를 평가 및 개선하고, 새로운 제품 또는 기능을 개발하고, 감사 및 문제 해결 활동을 수행하기 위해 설문조사 및 연구를 수행하고, 개발 중인 기능을 테스트하고, 보유한 정보를 분석합니다. </p>
		<p>5) 귀하와 소통합니다. 당사는 귀하에게 마케팅 커뮤니케이션을 보내고, 당사 서비스에 관해 귀하와 소통하며, 귀하에게 당사 정책 및 약관을 알리기 위해 귀하의 정보를 사용합니다. 또한 당사는 귀하가 당사에 연락할 때 귀하에게 응답하기 위해 귀하의 정보를 사용합니다. </p>
		<p>6) 광고와 서비스를 표시하고 측정합니다. 우리는 Whistle 서비스를 사용할 때와 사용하지 않을 때 모두 관련 광고를 표시하고 광고 및 서비스의 효과와 도달 범위를 측정할 수 있도록 광고 및 측정 시스템을 개선하기 위해 보유한 정보를 사용합니다. 당사 온라인 서비스의 광고에 대해 자세히 알아보고 귀하에게 표시되는 광고를 개인화하기 위해 귀하의 정보가 사용되는 방식을 제어하는 ​​방법을 알아보세요. </p>
		<p>7) 안전과 보안을 강화합니다. 우리는 의심스러운 활동이나 약관 및 정책 위반을 조사하는 등 계정 및 활동을 확인하고 Whistle 서비스 안팎에서 보안을 강화하는 데 필요한 정보를 사용합니다. 우리는 엔지니어 팀, 자동화 시스템, 암호화 및 기계 학습과 같은 고급 기술을 통해 귀하의 계정을 안전하게 유지하기 위해 열심히 노력하고 있습니다. 또한 귀하의 계정에 추가 보안 계층을 추가할 수 있는 사용하기 쉬운 보안 도구를 제공합니다. Whistle 보안 개선에 대한 자세한 내용을 보려면 Whistle 보안(도움말) 센터를 방문하세요. 당사는 서비스를 제공하고 지원하기 위해 쿠키 및 유사한 기술을 사용하며, 이러한 기술의 사용은 당사 정책의 이 섹션에 나열 및 설명되어 있습니다. 자세한 내용은 당사의 쿠키 정책을 읽어보십시오. </p>
		<h2>3조 이 정보는 어떻게 공유됩니까? </h2>
		<p>서비스 공유 사용자는 서비스를 통해 서로 연결하고 공유합니다. 당사는 이러한 목적을 달성하기 위해 다음과 같은 방법으로 귀하의 정보를 공유합니다:</p>
		<p>1. 귀하가 공유하고 소통하는 사용자입니다. 귀하가 당사 서비스를 통해 공유하고 의사소통할 때 귀하는 귀하가 공유하는 내용을 볼 수 있는 대상을 선택합니다. 예를 들어, Whistle에 게시물을 올릴 때 맞춤 개인 그룹, 모든 친구, 그룹 구성원 등 게시물의 공개 대상을 선택합니다. </p>
		<p>2. 공개 정보는 공개 파일의 정보나 Whistle 홈페이지나 기타 공개 포럼에서 공유된 콘텐츠를 포함하여 귀하가 공개적으로 공유하는 모든 정보를 의미합니다. 공개 정보는 당사의 온라인 및 오프라인 서비스를 통해 누구나 이용할 수 있으며 온라인 검색 엔진, API 및 오프라인 미디어(예: TV)를 통해 보거나 액세스할 수 있습니다. </p>
		<p>3. 경우에 따라 귀하가 공유하고 소통하는 사람들은 정보를 다운로드하거나 당사 서비스 및 기타 서비스를 통해 다른 사람들과 정보를 다시 공유할 수 있습니다. 귀하가 다른 사람의 게시물에 댓글을 달거나 Whistle에서 공유한 내용에 좋아요를 표시하면 해당 사람이 귀하의 댓글이나 좋아요를 볼 수 있는 청중을 결정합니다. 선택한 독자가 공개인 경우 귀하의 댓글도 공개됩니다. </p>
		<p>4. 다른 사람들이 귀하에 관한 정보를 공유하는 것을 보는 사용자. 다른 사람들은 당사 서비스를 사용하여 귀하에 관한 정보를 자신이 선택한 청중과 공유할 수 있습니다. 예를 들어, 이러한 사용자는 귀하의 사진을 공유하거나, 게시물에서 귀하를 언급하거나 태그하거나, 귀하가 공유한 정보를 공유할 수 있습니다. 사용자의 게시물에 대해 우려 사항이 있는 경우 소셜 보고서를 사용하면 신뢰할 수 있는 사람들로부터 빠르고 쉽게 도움을 받을 수 있습니다. 더 알아보기. </p>
		<p>5. 당사 서비스를 통합하거나 사용하는 제3자 애플리케이션 및 웹사이트. 당사의 서비스를 사용하거나 통합하는 제3자 애플리케이션, 웹사이트 또는 기타 서비스의 경우 귀하가 해당 서비스를 사용할 때 게시하거나 공유하는 정보를 받을 수 있습니다. 예를 들어, 귀하가 웹사이트에서 Whistle 친구들과 게임을 하거나 Whistle의 "댓글" 또는 "공유" 버튼을 사용하는 경우, 게임 개발자 또는 관련 웹사이트는 귀하의 게임 내 활동에 대한 정보를 얻거나 귀하가 게시한 정보를 받을 수 있습니다. 웹 사이트에서 Whistle에 대한 링크. 또한 귀하가 이러한 제3자 서비스를 다운로드하거나 사용할 때 해당 제3자는 귀하의 사용자 이름 또는 사용자 ID, 연령 그룹 및 국가/언어, 친구 목록 및 귀하가 그들과 공유하는 모든 정보를 포함하는 귀하의 공개 프로필에 액세스할 수 있습니다. 이러한 애플리케이션, 웹사이트 또는 통합 서비스에 의해 수집된 정보에는 해당 서비스의 자체 약관 및 정책이 적용됩니다. 이러한 앱과 웹사이트를 통해 공유되는 귀하 또는 다른 사람에 대한 정보를 제어할 수 있는 방법에 대해 자세히 알아보세요. </p>
		<p>6. Whistle 소유 회사와 공유합니다. 우리는 귀하에 대해 가지고 있는 정보를 Whistle 계열사와 공유합니다. Whistle의 회사에 대해 자세히 알아보세요. </p>
		<p>7. 새 소유자. 당사 서비스나 자산의 전부 또는 일부에 대한 소유권이나 통제권이 변경되는 경우 당사는 귀하의 정보를 새로운 소유자에게 이전할 수 있습니다. </p>
		<p>8. 제3자 파트너 및 고객과의 공유 당사는 회사를 운영하고 전 세계 사용자에게 무료 서비스를 제공할 수 있도록 서비스 제공 및 개선이나 광고 또는 관련 제품 사용을 돕는 제3자 회사와 협력합니다. 다음은 당사가 귀하에 관한 정보를 공유하는 제3자의 유형입니다:</p>
		<p>
		1) 광고, 측정 및 분석 서비스(개인 식별이 불가능한 정보만 해당). 우리는 광고가 서비스의 다른 콘텐츠만큼 관련성이 높고 흥미로워지기를 원합니다. 이를 염두에 두고 당사는 귀하에게 관련 광고를 표시하기 위해 필요한 정보를 사용합니다. 당사는 귀하의 허가가 없는 한 광고, 측정 및 분석을 제공하기 위해 귀하의 개인 식별 정보(귀하에게 연락하거나 식별할 수 있는 귀하의 이름 또는 이메일 주소와 같은 귀하를 식별하는 정보)를 사용하지 않습니다. 서비스 파트너는 공유합니다. 당사는 귀하의 개인 식별 정보를 제공하지 않고 이러한 파트너에게 광고의 도달 범위 및 효과에 대한 정보를 제공하거나, 귀하를 개인적으로 식별하지 않도록 정보를 집계합니다. 예를 들어, 당사는 광고주에게 광고가 얼마나 효과적인지, 광고를 보았거나 광고를 본 후 앱을 설치한 사람 수를 알려주거나, 광고주가 당사의 광고 지침을 준수하는 데 동의한 경우에만 해당 광고주에게 정보를 제공할 수 있습니다. 청중이나 고객을 이해하는 데 도움이 되는 인구통계학적 정보.
		2) 공급업체, 서비스 제공업체 및 기타 파트너. 당사는 중국 내 비즈니스를 지원하는 공급업체, 서비스 제공업체 및 기타 파트너에게 정보를 보냅니다. 이러한 지원에는 기술 인프라 서비스 제공, 서비스 사용 방법 분석, 광고 및 서비스 효과 측정, 고객 서비스 제공이 포함됩니다. 학문적 연구와 조사를 수행합니다. 이러한 파트너에게는 본 데이터 정책 및 당사와의 계약에 따라 엄격한 기밀 유지 의무가 적용됩니다.
		</p>
		<h2>제4조 나에 관한 정보를 어떻게 관리하거나 삭제하나요? </h2>
		<p>활동 로그를 사용하여 Whistle을 사용하는 동안 공유하는 콘텐츠와 정보를 관리할 수 있습니다. 정보 다운로드 도구를 통해 휘슬과 관련된 정보를 다운로드할 수도 있습니다. 당사는 귀하 및 다른 사람(위에 설명된 사용자 포함)에게 제품 및 서비스를 더 이상 제공할 필요가 없을 때까지 데이터를 보관합니다. 귀하의 계정과 관련된 정보는 당사가 제품 및 서비스를 제공하는 데 더 이상 데이터가 필요하지 않는 한 귀하의 계정이 삭제될 때까지 유지됩니다. </p>
		<p>언제든지 계정을 삭제할 수 있습니다. 귀하가 계정을 삭제하면 사진, 상태 업데이트 등 귀하가 게시한 콘텐츠가 삭제됩니다. 계정 삭제를 원하지 않지만 Whistle 사용을 일시적으로 중단하고 싶다면 계정을 비활성화할 수 있습니다. 계정 비활성화 또는 삭제에 대해 자세히 알아보려면 여기를 클릭하세요. 다른 사람이 귀하에 대해 공유한 정보는 귀하의 계정 정보에 포함되지 않으므로 귀하가 계정을 삭제해도 해당 정보는 삭제되지 않습니다. </p>
		<h2>5조 법적 요청에 어떻게 대응하거나 피해를 방지합니까? </h2>
		<p>법률에 따라 그렇게 해야 한다고 선의로 믿는 경우 법적 요청(예: 수색 영장, 법원 명령 또는 소환장)에 응답하여 귀하의 정보에 액세스하고, 보유하고, 공유할 수 있습니다. 해당 관할권의 법률에 따라 대응이 요구되고 해당 관할권의 사용자에게 영향을 미치며 국제적으로 인정되는 표준을 준수하는 한, 여기에는 중국 이외의 관할권의 법적 요구 사항이 포함될 수 있습니다. 또한 당사는 사기 및 기타 불법 행위를 탐지, 예방 및 대응하고(조사의 일환으로) 우리 자신과 귀하 및 타인을 보호하기 위해 필요하다고 선의로 믿는 경우 정보에 액세스하고, 보존하고, 공유할 수 있습니다. 사망 또는 심각한 개인 부상. 예를 들어, 당사는 귀하가 Whistle 서비스를 사용하거나 사용하지 않는 동안 사기 및 남용을 방지하기 위해 귀하의 계정 신뢰성에 대한 정보를 제3자 파트너에게 제공할 수 있습니다. Whistle을 사용한 구매와 관련된 금융 거래 데이터를 포함하여 법적 요청이나 의무, 정부 조사, ​​당사의 약관이나 정책 위반 가능성이 있는 조사와 관련하여 또는 피해를 방지하기 위해 귀하에 대한 정보를 수신하는 경우 다음과 같은 조치가 필요할 수 있습니다. 더 오랜 기간 동안 액세스, 처리 및 보관됩니다. 또한 당사는 약관 위반으로 인해 비활성화된 계정 정보를 최소 1년 동안 보관하여 약관을 반복적으로 위반하거나 위반하는 것을 방지합니다. Whistle은 본 정책에 설명된 목적을 달성하기 위해 회사 내에서 또는 제3자와 정보를 공유할 수 있습니다. </p>
		<h2>6조 본 정책의 변경 사항을 귀하에게 어떻게 통보합니까? </h2>
		<p>이 정책을 개정하기 전에 귀하에게 이를 통지할 것이며 귀하가 서비스를 계속 사용하기 전에 개정된 정책을 검토하고 의견을 제시할 수 있도록 할 것입니다. Whistle이 개인정보를 처리하는 방법에 대한 자세한 내용을 보려면 웹사이트 법적 고지, 개인정보 취급방침, 상표 고지 및 서비스 약관을 방문하세요. </p>
		<p>최종 수정일: 2025년 01월 15일</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/PrivacyPolicyZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/PrivacyPolicyEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/PrivacyPolicyKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/PrivacyPolicyJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h2 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
</style>